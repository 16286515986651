import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Grid, Paper } from '@mui/material';
import SupplyImage from '../assets/supply.webp';
import StationImage from '../assets/station.webp';
import QualityImage from '../assets/quality.webp';
import AgricultureImage from '../assets/agriculture.webp';
import { GoldenRatioImage } from '../styles/commonStyles';
import { useTranslation } from 'react-i18next';

const ProductionSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box mt={4} mb={6}>
      <Typography variant="h3" align="center" gutterBottom>
        {t('nav.produce')}
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {t('produce.sec1.ttl')}
          </Typography>
          <Typography paragraph>{t('produce.sec1.txt1')}</Typography>
          <Typography paragraph>{t('produce.sec1.txt2')}</Typography>
          <Typography paragraph>{t('produce.sec1.txt3')}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={StationImage} alt="Station" />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={QualityImage} alt="Quality" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {t('produce.sec2.ttl')}
          </Typography>
          <Typography paragraph>{t('produce.sec2.txt1')}</Typography>
          <Typography paragraph>{t('produce.sec2.txt2')}</Typography>
          <Typography paragraph>{t('produce.sec2.txt3')}</Typography>
          <Typography paragraph>{t('produce.sec2.txt4')}</Typography>
          <Typography paragraph>{t('produce.sec2.txt5')}</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {t('produce.sec3.ttl')}
          </Typography>
          <Typography paragraph>{t('produce.sec3.txt1')}</Typography>
          <Typography paragraph>{t('produce.sec3.txt2')}</Typography>
          <Typography paragraph>{t('produce.sec3.txt3')}</Typography>
          <Typography paragraph>{t('produce.sec3.txt4')}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={SupplyImage} alt="Supply" />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={AgricultureImage} alt="Agriculture" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {t('produce.sec4.ttl')}
          </Typography>
          <Typography paragraph>{t('produce.sec4.txt1')}</Typography>
          <Typography paragraph>{t('produce.sec4.txt2')}</Typography>
          <Typography paragraph>
            <Link
              to="https://www.roblox.com/games/18455984842/unnamed"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('produce.sec4.txt3')}
            </Link>
          </Typography>
          <Typography paragraph>
            <Link
              to="https://www.roblox.com/games/12852980662/unnamed"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('produce.sec4.txt4')}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductionSection;
