import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Grid, Paper } from '@mui/material';
import MiniTomatoImage from '../assets/minitomato.webp';
import MiniTomatoBoxImage from '../assets/tomato.webp';
import { GoldenRatioImage } from '../styles/commonStyles';
import { useTranslation } from 'react-i18next';

const ServiceSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box mt={4} mb={6}>
      <Typography variant="h3" align="center" gutterBottom>
        {t('nav.service')}
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {t('service.sec1.ttl')}
          </Typography>
          <Typography paragraph>{t('service.sec1.txt1')}</Typography>
          <Typography paragraph>{t('service.sec1.txt2')}</Typography>
          <Typography paragraph>
            <Link to="https://jp.mercari.com/shops/product/7VUgWnKMqpv3CiVoG3Feue">
              {t('service.sec1.txt3')}
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={MiniTomatoImage} alt="Mini Tomato" />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={MiniTomatoBoxImage} alt="Mini Tomato" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {t('service.sec2.ttl')}
          </Typography>
          <Typography paragraph>{t('service.sec2.txt1')}</Typography>
          <Typography paragraph>{t('service.sec2.txt2')}</Typography>
          <Typography paragraph>
            <Link to="https://jp.mercari.com/shops/product/7VUgWnKMqpv3CiVoG3Feue">
              {t('service.sec2.txt3')}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServiceSection;
