import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ContactSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box mt={4} mb={6}>
      <Typography variant="h3" align="center" gutterBottom>
        {t('nav.contact')}
      </Typography>
      <Typography align="center">
        <Link to="/contact-form">{t('contact.btn.form')}</Link>
      </Typography>
    </Box>
  );
};

export default ContactSection;
